<template>
    <Dialog :style="{width: '450px'}" v-model:visible="showDialog" :showHeader="false" :modal="true" :dismissableMask="true">
        <div class="dialog-header">
            <img :src="logo_src">
        </div>

        <form @submit.prevent="handleSubmit()">
            <div class="field">
                <div class="label">Email</div>
                <InputText id="username" v-model="form.username" required="true" autofocus :class="{'p-invalid': submitted && !form.username}" />
                <small v-if="submitted && !form.username">Preencha o email</small>
            </div>

            <div class="field">
                <div class="label">Senha</div>
                <Password id="password" v-model="form.password" toggleMask required="true" :feedback="false" :class="{'p-invalid': submitted && !form.password}" autofocus/>
                <small v-if="submitted && !form.password">Preencha a senha</small>
            </div>

            <Button 
                class="submit-button"
                type="submit" 
                label="Entrar" 
                :loading="loading"
            />
        </form>

        <div class="dialog-footer">
            Ainda não possui uma conta? 
            <Button class="button-navigate" @click="handleNavigateToRegister()">
                Cadastrar-se
            </Button>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import InputText from 'primevue/inputtext';
    import InputMask from 'primevue/inputmask';
    import Button from 'primevue/button';
    import Password from 'primevue/password';
    import { getUnixTime } from "date-fns";

    export default {
        name: "ModalLogin",

        props: {
            dialog: Boolean,
        },
        
        components: {
            Dialog,
            Button,
            InputText,
            InputMask,
            Password
        },

        data() {
            return {
                form: {},
                submitted: false,
                showDialog: false,
                loading: false,

                site_id: null,
            }
        },

        watch: {
            dialog: function (val) {
                this.showDialog = val;

                if(!val) {
                    this.showDialog = val;
                    this.clearFields()
                }
            },
        },

        created() {
		    this.logo_src = this.$store.getters.getSiteConfig?.logo_horizontal_url
            this.site_id = this.$store.getters.getSiteConfig?.id
        },

        methods: {
            handleNavigateToRegister() {
                this.hideLoginModal()
                this.showRegisterModal()
            },

            handleSubmit () {
                if(this.loading) {
                    return
                }

                this.submitted = true
                this.loading = true

                const payload = {
                    ...this.form,
                    site_id: this.site_id
                }

                this.$api.post("/auth/login", payload)
                .then(response => {
                    this.access_token = response.data.access_token;
                    let expire_date = getUnixTime(new Date);
                    expire_date += response.data.expires_in - 300;

                    this.$api.get("/auth/me", {
                        headers: {
                            Authorization: `Bearer ${response.data.access_token}`
                        }
                    })
                    .then(response => {
                        this.user_logged = response.data;
                        this.loading = false

                        if (this.user_logged.site_id != this.site_id) {
                            this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Verique os dados e tente novamente.', life: 3000 });

                            // KEEPING IN VUEX 
                            this.$store.dispatch("setLogged", {
                                isLogged: false,
                                user: {},
                                token: '',
                                expire: expire_date
                            });
                            return;
                        }

                        // apenas cambistas tem acesso
                        if (this.user_logged.type != "cambista") {
                            this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Usuário não permitido.', life: 3000 });

                            // KEEPING IN VUEX 
                            this.$store.dispatch("setLogged", {
                                isLogged: false,
                                user: {},
                                token: '',
                                expire: expire_date
                            });
                            return;
                        }

                        if (this.user_logged.situation == "bloqueado") {
                            this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Terminal bloqueado.', life: 3000 });

                            // KEEPING IN VUEX 
                            this.$store.dispatch("setLogged", {
                                isLogged: false,
                                user: {},
                                token: '',
                                expire: expire_date
                            });
                            return;
                        }


                        // KEEPING IN VUEX 
                        this.$store.dispatch("setLogged", {
                            isLogged: true,
                            user: this.user_logged,
                            token: this.access_token,
                            expire: expire_date,
                            talao: this.user_logged.talao
                        });

                        this.$api.get('/regions/regionConfig', {
                            headers: {
                                Authorization: this.accessToken()
                            },
                            params: {
                                id: this.user_logged.region_id
                            }
                        })
                        .then((response) => {
                            this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Login realizado com sucesso!', life: 3000 });
                            this.clearFields()

                            this.$store.dispatch("setRegionConfig", {
                                regionConfig: response.data,
                            });

                            this.$store.dispatch("setProducts", {
                                products: {
                                    tradicional: {...response.data.products.find(product => product.name == 'tradicional')},
                                    ligeirinha: {...response.data.products.find(product => product.name == 'ligeirinha')},
                                    instantaneo: {...response.data.products.find(product => product.name == 'instantaneo')},
                                    bauzinho: {...response.data.products.find(product => product.name == 'bauzinho')},
                                    bolao_dezenas: {...response.data.products.find(product => product.name == 'bolao de dezenas')},
                                    quininha: {...response.data.products.find(product => product.name == 'quininha')},
                                    quina_brasil: {...response.data.products.find(product => product.name == 'quina brasil')},
                                    seninha: {...response.data.products.find(product => product.name == 'seninha')},
                                    lt: {...response.data.products.find(product => product.name == 'lt')},
                                }
                            });

                            this.$router.push({
                                path: "/"
                            })

                            setTimeout(() => {
                                window.location.reload();
                            }, 300);

                        })

                    })
                    .catch((err) => {
                        this.loading = false
                        this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.error, life: 3000 });
                    });

                })
                .catch(() => {
                    this.loading = false
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Verique os dados e tente novamente.', life: 3000 });
                });

            },

            clearFields() {
                this.form = {}
                this.submitted = false
                this.loading = false
            }
        },

    }
</script>


<style lang="scss" scoped>
    .dialog-header {
        display: flex;
        flex-direction: column;
        height: 30px;
        margin-bottom: 20px;
        text-align: center;
        gap: 10px;
        font-weight: 500;
        align-items: center;

        img {
            height: 100%;
        }

        h4 {
            margin-top: 10px;
            font-weight: 500;
            font-size: 18px;
        }
    }
    
    .field {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .label {
            color: #cdcdcd;
            font-size: 14px;
            margin-bottom: 5px;
        }
    }

    .submit-button {
        font-size: 14px;
        font-weight: 500;
    }

    .dialog-footer {
        margin-top: 20px;
        text-align: center;
        color: #cdcdcd;
        font-size: 14px;

        .button-navigate {
            all: unset;
            cursor: pointer;
            background: transparent !important;
            font-weight: bold;
            color: var(--app-primary-color) !important;
        }
    }

    .p-inputtext {
        background: #20262e !important;
    }

    .p-invalid {
        border: solid #b63642 1px !important;
    }

    .p-inputtext:enabled:focus {
        box-shadow: none !important;
        border: solid var(--app-primary-color) 1px !important;
    }
</style>