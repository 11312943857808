<template>
    <Dialog :style="{width: '450px'}" v-model:visible="showDialog" :showHeader="false" :modal="true" :dismissableMask="true">
        <div class="dialog-header">
            <img :src="logo_src">
        </div>

        <p style="text-align: center;" class="mt-3 mb-3">FAÇA SEU CADASTRO AGORA E COMECE A LUCRAR!</p>

        <form @submit.prevent="handleSubmit()">
            <div class="field">
                <div class="label">
                    <span>CPF</span>
                    <span class="red"> *</span>
                </div>
                <InputMask id="cpf" mask="999.999.999-99" v-model="form.cpf" required="true" autofocus :class="{'p-invalid': submitted && !form.cpf}" />
                <small v-if="submitted && !form.cpf">Preencha o cpf</small>
            </div>

            <!-- <div class="field">
                <div class="label">
                    <span>CEP</span>
                    <span class="red"> *</span>
                </div>
                <InputMask id="cep" mask="99999-999" v-model="form.cep" required="true" autofocus :class="{'p-invalid': submitted && !form.cep}" />
                <small v-if="submitted && !form.cep">Preencha o cep</small>
            </div> -->

            <div class="field">
                <div class="label">
                    <span>Email</span>
                    <span class="red"> *</span>
                </div>
                <InputText id="email" v-model="form.email" required="true" autofocus :class="{'p-invalid': submitted && !form.email}" />
                <small v-if="submitted && !form.email">Preencha o email</small>
            </div>

            <div class="field">
                <div class="label">
                    <span>Senha</span>
                    <span class="red"> *</span>
                </div>
                <Password id="password" v-model="form.password" toggleMask required="true" :feedback="false" :class="{'p-invalid': submitted && !form.password}" autofocus/>
                <small v-if="submitted && !form.password">Preencha a senha</small>
            </div>

            <div class="field">
                <div class="label">
                    <span>Telefone</span>
                    <span class="red"> *</span>
                </div>
                <InputMask id="phone"  mask="(99) 999999999" v-model="form.phone" required="true" autofocus :class="{'p-invalid': submitted && !form.phone}" />
                <small v-if="submitted && !form.phone">Preencha o telefone</small>
            </div>

            <Button 
                class="submit-button"
                type="submit" 
                label="Criar conta" 
                :loading="loading"
            />
        </form>

        <div class="dialog-footer">
            Já possui uma conta? 
            <Button class="button-navigate" @click="handleNavigateToLogin()">
                Fazer login
            </Button>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import InputText from 'primevue/inputtext';
    import InputMask from 'primevue/inputmask';
    import Button from 'primevue/button';
    import Password from 'primevue/password';

    export default {
        name: "ModalRegister",

        props: {
            dialog: Boolean,
        },
        
        components: {
            Dialog,
            Button,
            InputText,
            InputMask,
            Password
        },

        data() {
            return {
                form: {},
                submitted: false,
                showDialog: false,
                loading: false,

                site_id: null,
                code: null
            }
        },

        watch: {
            dialog: function (val) {
                this.code = this.$store.getters.getRegisterCode
                this.showDialog = val;

                if(!val) {
                    this.showDialog = val;
                    this.clearFields()
                }
            },
        },

        created() {
		    this.logo_src = this.$store.getters.getSiteConfig?.logo_horizontal_url
            this.site_id = this.$store.getters.getSiteConfig?.id
        },

        methods: {
            handleNavigateToLogin() {
                this.hideRegisterModal()
                this.showLoginModal()
            },

            handleSubmit() {
                if(this.loading) {
                    return
                }

                this.submitted = true
                this.loading = true

                const payload = {
                    ...this.form,
                    site_id: this.site_id,
                    code: this.code,
                    fbc_cookie: this.getFbcCookie(),
                    fbp_cookie: this.getFbpCookie()
                }

                this.$api.post('/online/storeUser', payload, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken()}`
                    },
                })
                .then(() => {
                    this.loading = false
                    this.$toast.add({severity: 'success', summary: 'Sucesso', detail:  'Cadastro realizado com sucesso!', life: 3000});
                    
                    this.$router.push({
                        path: "/"
                    })

                    setTimeout(() => {
                        window.location.reload();
                    }, 3000); 
                })
                .catch((e) => {
                    this.loading = false
                    this.$toast.add({severity:'error', summary: 'Erro', detail:e.response.data.message, life: 3000});
                });
            },

            clearFields() {
                this.form = {}
                this.submitted = false
                this.loading = false
            }
        },

    }
</script>


<style lang="scss" scoped>
    .dialog-header {
        display: flex;
        flex-direction: column;
        height: 30px;
        margin-bottom: 20px;
        text-align: center;
        gap: 10px;
        font-weight: 500;
        align-items: center;

        img {
            height: 100%;
        }

        h4 {
            margin-top: 10px;
            font-weight: 500;
            font-size: 18px;
        }
    }
    
    .field {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .label {
            color: #cdcdcd;
            font-size: 14px;
            margin-bottom: 5px;
        }
    }

    .submit-button {
        font-size: 14px;
        font-weight: 500;
    }

    .dialog-footer {
        margin-top: 20px;
        text-align: center;
        color: #cdcdcd;
        font-size: 14px;

        .button-navigate {
            all: unset;
            cursor: pointer;
            background: transparent !important;
            font-weight: bold;
            color: var(--app-primary-color) !important;
        }
    }

    .p-inputtext {
        background: #20262e !important;
    }

    .p-invalid {
        border: solid #b63642 1px !important;
    }

    .p-inputtext:enabled:focus {
        box-shadow: none !important;
        border: solid var(--app-primary-color) 1px !important;
    }

    .red {
        color:#d22424;
    }

</style>