<template>
    <div class="carousel-container">

		<div class="carousel-items" :style="carouselStyles"
			v-touch:swipe.right="prevSlide"
			v-touch:swipe.left="nextSlide"
		>
			<div @click="navigateToPage(banner.redirect_to), pauseAutoplay" v-for="(banner, index) in banners" 
				:key="index" 
				:style="{ transform: `translateX(-${currentIndex * 100}%)`, transitionDuration: `500ms` }" 
				class="carousel-slide"
			>
				<img :src="banner.banner_url" :alt="'banner'" />
			</div>
		</div>
        

        <button @click="prevSlide" class="nav-button prev-button">&#10094;</button>
        <button @click="nextSlide" class="nav-button next-button">&#10095;</button>

        <div class="carousel-indicators">
            <span v-for="(banner, index) in banners" 
                  :key="index" 
                  @click="jumpToSlide(index)" 
                  :class="{ active: currentIndex === index }"
            ></span>
        </div>
    </div>
</template>

<script>
export default {
	
	props: {
		banners: {
			type: Array,
			default: () => [],
		},
		numVisible: {
			type: Number,
			default: 1
		},
		circular: {
			type: Boolean,
			default: true
		},
		autoPlay: {
			type: Boolean,
			default: true
		},
		transitionInterval: {
			type: Number,
			default: 5000
		}
	},

	data() {
        return {
            currentIndex: 0,
            interval: null,
			isAutoplayPaused: false,
			windowWidth: window.innerWidth,
        }
    },

	computed: {
		carouselStyles() {
			let height = '300px'; // Altura padrão

			// Ajusta a altura com base na largura da janela
			if (window.innerWidth <= 767) {
				height = '170px';
			} else if (window.innerWidth <= 991) {
				height = '250px';
			} else if (window.innerWidth <= 1199) {
				height = '300px';
			}

			return { height: height };
		}
	},


	mounted() {
		if (this.autoPlay) {
			this.startAutoPlay();
		}
	},

	beforeUnmount() {
		clearInterval(this.interval);
	},

	methods: {
		navigateToPage(page) {
			document.location = page
		},

		pauseAutoplay() {
			clearInterval(this.interval);
			this.isAutoplayPaused = true;
		},

		resumeAutoplay() {
			if (this.isAutoplayPaused && this.autoPlay) {
				this.startAutoPlay();
				this.isAutoplayPaused = false;
			}
		},

		resetAutoplay() {
			this.pauseAutoplay();
			setTimeout(this.resumeAutoplay, 5000);  // Retoma o autoplay após 5 segundos de inatividade
		},
		
		nextSlide() {
			if (this.currentIndex < this.banners.length - 1) {
				this.currentIndex++;
			} else if (this.circular) {
				this.currentIndex = 0;
			}
			this.resetAutoplay();
		},


		prevSlide() {
			console.log('prev')

			if (this.currentIndex > 0) {
				this.currentIndex--;
			} else if (this.circular) {
				this.currentIndex = this.banners.length - 1;
			}
			this.resetAutoplay();
		},

		jumpToSlide(index) {
			this.currentIndex = index;
			this.resetAutoplay();
		},

		startAutoPlay() {
			this.interval = setInterval(this.nextSlide, this.transitionInterval);
		},
	}
}
</script>
  
<style scoped>
.carousel-container {
	display: flex;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	width: 100%;
}
.carousel-items {
	display: flex;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	width: 100%;
}
.carousel-slide {
	display: inline-block;
	transition: transform 0.5s;
	flex: 0 0 100%;
}
.carousel-slide > img {
	object-fit: cover;
	object-position: bottom;
	height: 100%;
	width: 100%;
}
.nav-button {
	display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(255 255 255);
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: black;
}
.prev-button {
	left: 15px;
}
.next-button {
	right: 15px;
}
.carousel-indicators {
	position: absolute;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 5px;
}
.carousel-indicators span {
	width: 12px;
	height: 4px;
	border-radius: 5px;
	background-color: #a19496;
	cursor: pointer;
	transition: background-color 0.3s;
}
.carousel-indicators span.active {
	background-color: white;
	width: 25px;
}

@media (min-width: 768px) {
    .carousel-container:hover .nav-button {
        display: flex;
    }
}

@media (max-width: 1600px) {
    .carousel-items {
        height: 300px;
    }
}

@media (max-width: 991px) {
    .carousel-items {
        height: 250px;
    }
}

@media (max-width: 767px) {
    .carousel-items {
        height: 200px;
    }
	.nav-button {
        display: flex;
		font-size: 14px;
		width: 25px;
		height: 25px;
    }
}
</style>
